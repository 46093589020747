import { useEffect } from 'react';
import { useGlobalState } from 'src/hooks/use-global-state';
import PageLayout from 'src/layouts/default';
import { Alert, AlertType } from 'barn/components/Alert';
import { GettingStartedProgress } from '../components/GettingStartedProgress';
import KeyStats from '../components/KeyStats';
import SubscriberStats from '../components/SubscriberStats';
import Welcome from '../components/Welcome';

export const motionVariants = {
  initial: { maxHeight: '0px', opacity: 0 },
  animate: {
    // We want to animate and we can't do that on height: auto.
    // Large maxHeight ensures that it will get animated in every case
    maxHeight: '100vh',
    opacity: 1,
    transition: { duration: 0.75, ease: [0.64, 0, 0.56, 1] },
  },
};

const Home = () => {
  const {
    state: {
      reports: {
        filters: { timeFilter, granularity },
      },
    },
    actions,
  } = useGlobalState('home');

  // fetch reports on initial load
  useEffect(() => {
    actions.fetchGettingStarted();
    actions.getNumberVerificationStatus();
    actions.getSyncSubscribers();
    actions.fetchSubscriberGrowthReport({
      timeWindow: timeFilter[2].value,
      granularity,
    });
  }, []);

  return (
    <PageLayout>
      <div
        style={{
          paddingTop: '20px',
        }}
      >
        <Alert type={AlertType.Error}>
          Reachbee will be taken offline{' '}
          <strong
            style={{
              fontWeight: 'bold',
            }}
          >
            31st October, 2024
          </strong>
          . Please continue to{' '}
          <a
            href='https://apps.shopify.com/pushowl?utm_source=reachbee'
            target='_blank'
            style={{
              color: '#fff',
              fontWeight: '500',
              textDecoration: 'underline',
            }}
            rel='noreferrer'
          >
            PushOwl
          </a>
        </Alert>
      </div>
      <Welcome />
      <GettingStartedProgress motionVariants={motionVariants} />
      <KeyStats />
      <SubscriberStats />
    </PageLayout>
  );
};

export default Home;
