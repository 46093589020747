import styled from 'styled-components';
import InfoRoundedFilledIcon from 'barn/icons/InfoRoundedFilled';
import AlertIcon from 'barn/icons/Alert';
import tokens from 'barn/tokens';

const StyledProtip = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: ${({ theme }) => theme.alert.bg};
  border-radius: ${tokens.radii[3]};
  color: ${({ theme }) => theme.alert.color};
  padding: ${tokens.space.padding[3]} ${tokens.space.padding[4]}
    ${tokens.space.padding[3]} 0;

  &[data-type='warning'] {
    background-color: ${({ theme }) => theme.alert.warning.bg};
  }
  &[data-type='error'] {
    color: #fff;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.alert.error.bg};

    .icon {
      color: #fff;
      height: 17px;
    }
  }

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: ${({ theme }) => theme.alert.icon.color};
  }
`;
export enum AlertType {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}
interface AlertProps {
  type?: AlertType;
  hideIcon?: boolean;
  testHandle?: string;
}
export const Alert: React.FunctionComponent<AlertProps> = ({
  type = AlertType.Info,
  hideIcon = false,
  children,
  testHandle,
}) => {
  return (
    <StyledProtip data-type={type} data-test={testHandle}>
      {!hideIcon && (
        <div className='icon'>
          {type === AlertType.Info && <InfoRoundedFilledIcon size={22} />}
          {type === AlertType.Warning && <AlertIcon size={22} />}
          {type === AlertType.Error && <AlertIcon size={22} />}
        </div>
      )}
      <p>{children}</p>
    </StyledProtip>
  );
};
